import http from "../services/http";

const getSessionId = async () => {
  const url = `${process.env.REACT_APP_API_URL}/create-session`;
  const response = await http.get(url);
  const data = response;
  return data;
};

const getResult = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/face-liveness/${id}`;
  const response = await http.get(url);

  return response;
};



export const solicitud = {
  getResult: getResult,
  getSessionId: getSessionId,
};
